import React, { Component } from "react";
import { connect } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { changeFirstLoggedIn, getListBeranda } from "../../redux/actions";
import { formatTime, formatDate } from "../../helpers/functionFormat";
import {getProfile} from "../../redux/actions/auth";

class Beranda extends Component {
  _mounted = true;

  state = {
    location: "",
    date: "",
    time: "",
    listData: [],
    loading: true,
    showUpdatePassword: false,
    tableState: {
      search: "",
    },
  };

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.intervalTime);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      if (this.props.isFirstLoggedIn) {
        this.props.onChangeFirstLoggedIn();
      }
    }
    this.updateTime();
    this.loadData();
    this.intervalTime = setInterval(() => this.updateTime(), 1000);
    this.props.onGetProfile();
    this.props.requiredAction === 'change_password' ?
      this.setState({showUpdatePassword: true}) :
      this.setState({showUpdatePassword: false})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableState !== this.state.tableState) {
      this.loadData();
    }
  }

  updateTime() {
    let date = new Date();
    if (this._mounted) {
      this.setState({
        date: formatDate(date, 3),
        time: formatTime(date),
      });
    }
  }

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const data = await this.props.onGetListBeranda(this.state.tableState);
      if (this._mounted) {
        this.setState({ listData: data.data.data, loading: false });
      }
      if (data?.data?.status_code === 401) {
        this.props.history.push('/login');
        window.location.reload();
      }
      console.log('DATA', data?.data?.status_code);
    } catch (error) {
      if (this._mounted) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    return (
      <div className="pb-4">
        <DotLoaderOverlay
          loading={this.state?.loading}
          overlayColor="rgba(0,0,0,0.1)"
          color={"#CF1701"}
        />
        <div className="flex px-1 md:px-24 my-8">
          <div className="md:flex md:justify-between bg-white w-2/3 md:w-4/5 p-2 rounded-lg items-center md:px-4 card">
            <span className="text-third font-semibold text-md md:text-sm">
              Jakarta | {this.state?.date}
            </span>
            <div className="flex items-center md:mt-0 mt-1">
              <Clock className="mr-1 w-4 md:mr-2 md:w-6" />
              <span className="text-third text-sm">{this.state?.time}</span>
            </div>
          </div>
          <Button
            bgColor="bg-third"
            className="w-1/3 md:w-1/5 ml-4 px-0 md:px-6"
            title="Lihat Riwayat"
            onClick={() => this.props.history.push("/riwayat")}
          />
        </div>
        {this.state?.showUpdatePassword && (
          <div className="mx-1 md:mx-24 bg-amber-300 mb-6 card p-3 justify-between flex flex-row">
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
              </div>
              <span>Untuk meningkatkan keamanan, mohon menghubungi admin untuk mengupdate password</span>
            </div>
            {/*<div className="cursor-pointer" onClick={() => this.setState({showUpdatePassword: false})}>*/}
            {/*  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">*/}
            {/*    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />*/}
            {/*  </svg>*/}
            {/*</div>*/}
          </div>
        )}
        <div className="mx-1 md:mx-24 bg-white rounded-lg py-1 card min-h-5/6">
          <div className="flex items-center justify-between py-2 px-4 border-b">
            <h3 className="text-lg font-bold">Jadwal Anda</h3>
            <div className="md:w-1/4 w-2/3">
              <TextInput
                className="flex flex-1 border-0 px-1 m-1 font-sourceSansPro focus:outline-none focus:border-none"
                lefticon={<Search />}
                placeholder="Cari lot atau tipe mobil"
                value={this.state.tableState.search}
                onChange={(e) =>
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      tableState: {
                        ...prevState.tableState,
                        search: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
          </div>
          {this.state.listData.map((item, index) => (
            <div
              key={index.toString()}
              className={`flex items-center px-4 py-2 justify-between ${
                index % 2 === 1 ? "bg-white" : "bg-[#F9FAFB]"
              } ${index === this.state?.listData?.length - 1 ? "border-b" : ""}`}
            >
              <div className="md:w-5/12 w-1/2">
                <label className="block text-third text-md font-semibold mb-1">
                  Lelang {item.location.city.name} #{item.auction_number}
                </label>
                <label className="hidden md:block text-sm text-secondary">
                  {`${item?.location?.city?.name} ● ${formatDate(
                    item?.date_start,
                    3
                  )}, ${item?.time_start}`}
                </label>
                <label className="block md:hidden text-sm text-secondary">
                  {item?.date_start}
                </label>
                <label className="block md:hidden text-sm text-secondary">
                  {item?.time_start}
                </label>
              </div>
              <div className="md:w-5/12 w-1/2 mx-1">
                <label className="block text-third text-md font-semibold mb-1">
                  {item?.name}
                </label>
                <label className="block text-sm text-secondary">
                  {item?.role}
                </label>
              </div>
              <div className="block">
                <Button
                  bgColor="bg-white"
                  className="text-sm font-semibold border border-secondary hover:bg-gray-200"
                  title="Mulai Lelang"
                  onClick={() =>
                    this.props.history.push(`/live-bidding/${item.id}`)
                  }
                  color="text-third"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requiredAction: state.auth.profile.requiredAction,
    isFirstLoggedIn: state.auth.isFirstLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeFirstLoggedIn: () => dispatch(changeFirstLoggedIn(false)),
    onGetListBeranda: (params) => dispatch(getListBeranda(params)),
    onGetProfile: () => dispatch(getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Beranda);
