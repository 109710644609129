import React from 'react';

const ToggleSwitch = ({ label, value, onChange }) => {
    return (
        <div>
            <div className='flex items-center justify-center mt-1'>
                <label className="relative inline-flex justify-center items-center cursor-pointer">
                    <input type="checkbox" onChange={onChange} value={value} className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary dark:peer-focus:ring-secondary rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-secondary peer-checked:bg-primary"></div>
                </label>
            </div>
            <div className='flex justify-center'>
                <span className="text-sm">{label}</span>
            </div>
        </div>
    )
}

export default ToggleSwitch;